<template>
  <div>
    <el-dialog
        title="沟通图片"
        width="70%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div style="height: 600px; width:100%; overflow: auto;text-align: center">
        <el-row>
          <el-col>
            <el-empty :image-size="200" v-if="commUrl === '' || commUrl === undefined" description="暂无沟通图片"></el-empty>
            <el-collapse v-model="activeImages" v-else>
                <el-image style="width: 40%;height: 500px" :src="imageUrl" :preview-src-list="[imageUrl]">
                </el-image>
            </el-collapse>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "comm-picture",
  props: {
    orderImageState: {
      type: Boolean,
      default: false,
      required: true,
    },
    commUrl: {
      type: String,
      required: true,
    }
  },
  created() {
    this.setImageUrl()
  },
  data() {
    return {
      state: this.orderImageState,
      activeImages: [],
      loading: false,
      tenantCrop: localStorage.getItem("tenantCrop"),
      baseURL: "https://communicate-image-1304365928.cos.ap-shanghai.myqcloud.com/",
      imageUrl: "",
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setImageUrl() {
      this.imageUrl = this.baseURL + this.commUrl;
    }
  }
}
</script>

<style>
</style>