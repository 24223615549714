<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
            <el-input v-model="search.name" placeholder="客户姓名" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.weChat" placeholder="微信" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.phone" placeholder="手机号" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.service" placeholder="客服" size="medium" clearable>
            <el-option
                v-for="item in serviceArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-tooltip class="item" effect="dark" content="沟通日期" placement="top">
            <el-date-picker
                style="width: 100%"
                size="medium"
                v-model="dateSectionArray"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-tooltip>

        </el-col>
        <el-col :span="5">
          <el-button type="primary"  @click="searchComm" size="medium">搜索</el-button>
          <el-button type="warning"  @click="reset" size="medium">重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :data="commArray"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          border
          max-height="700"
          style="width: 99%">
        <el-table-column prop="name" label="客户名" align="center"></el-table-column>
        <el-table-column prop="commDate" label="沟通时间" sortable align="center"></el-table-column>
        <el-table-column prop="commType" label="沟通方式" align="center"></el-table-column>
        <el-table-column prop="commNote" label="沟通内容" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="grade" label="客户意愿程度" align="center"></el-table-column>
        <el-table-column prop="commCycle" label="沟通周期" align="center"></el-table-column>
        <el-table-column prop="commNextDate" label="预计下次沟通日期" align="center"></el-table-column>
        <el-table-column prop="service" label="所属人" align="center"></el-table-column>
        <el-table-column v-if="false" prop="commImage" label="沟通图片" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="open(scope.row.commImage)">查看图片</el-button>
              <per-button type="primary" size="mini" @click="remind(scope.row)" per="comm:ignore">忽略沟通</per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <commImage :orderImageState="commImageState" v-if="commImageState" v-bind:commUrl="commUrl" @close="commImageClose"></commImage>
  </div>
</template>

<script>
import commImage from "@/pages/customer/comm/comm-picture"
export default {
  name: "comm-list",
  components: {
    commImage,
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      commUrl:"",
      showViewer:false,
      commImageState:false,
      search: {
        // 客户姓名
        name: "",
        // 微信
        weChat: "",
        // 手机号
        phone: "",
        // 沟通日期
        dateSection: "",
        // 客服
        service: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      serviceArray: [],
      dateSectionArray: [],
      commArray: [],
      total: 0,
      page: 1,
      limit: 100,
    }
  },
  methods: {
    commImageClose(){
      this.commImageState = false
    },
    open(data) {
      this.commUrl = data;
      this.commImageState = true
    },
    pageInit() {
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
      this.queryCommList()
    },
    searchComm() {
      if (this.dateSectionArray == null || this.dateSectionArray.length == 0) {
        this.search.dateSection = ""
      } else if (this.dateSectionArray.length > 0) {
        this.search.dateSection = this.dateSectionArray[0] +" - "+ this.dateSectionArray[1]
      }
      this.queryCommList()
    },
    queryCommList() {
      this.$axios({
        method: "GET",
        url: "/comm/commListByTenant",
        params: {
          ...this.search,
          limit: this.limit,
          page: this.page,
        }
      }).then(response => {
        this.commArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryCommList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryCommList()
    },
    remind(data) {
      this.$axios({
        method: "PUT",
        url: "/comm/ignore/" + data.id,
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '提醒已忽略': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.queryCusList()
      })
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
    }
  }
}
</script>

<style scoped>

</style>